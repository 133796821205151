@import './token.css';
@import './core-colors.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  
  .main {
    @apply mt-10 mx-auto container w-1/3 prose;
  }

  .form {
    @apply grid grid-cols-1 gap-6;
  }

  .form label[data-required="true"]::after {
    @apply ml-1 text-red-500 content-['*'];
  }

  .form input[type="text"],
  .form input[type="email"],
  .form input[type="date"],
  .form input[type="password"],
  .form select,
  .form textarea {
    @apply mt-2 block;

    @apply focus:border-[#228BE6] focus:ring focus:ring-[#228BE6] focus:ring-opacity-0;
  
  }

  .form input[type="checkbox"] {
    @apply shadow-[inset_0px_2px_4px_rgba(0,0,0,0.06)] appearance-none bg-white bg-origin-border border-neutral-300 rounded text-[#FFBA3F] fill-[black] inline-block shrink-0 h-6 select-none align-middle w-6 mr-3 p-0 border-2;
  

    /* @apply focus:border-indigo-300 focus:ring focus:ring-offset-0 focus:ring-indigo-200 focus:ring-opacity-50; */
    @apply focus:border-[#D4D4D4] focus:ring focus:ring-offset-0 focus:ring-transparent focus:ring-opacity-50;
  }

  .form input[type="checkbox"]:checked {
    @apply bg-[url('../assets/checkbox-icon.svg')] bg-current bg-[50%] bg-no-repeat bg-[80%_80%] border-transparent;

  }

  input[type="checkbox"]::after {
    @apply text-base text-[black]
  }

  .form p.error-message {
    @apply text-red-500;
  }

  .btn-container {
    @apply flex flex-row justify-center items-stretch mt-6 gap-x-4;
  }

  .btn {
    @apply flex flex-1 content-center justify-center gap-3 rounded border-2 border-solid px-6 py-3 text-center font-semibold text-neutral-900 transition-all duration-200;
  }

  .btn-primary {
    @apply border-[#FFBA3F] bg-[#FFBA3F] hover:border-[#FFCE66] hover:bg-[#FFCE66];
  }
  .btn-secondary {
    @apply border-neutral-900 hover:border-[#FF9900] hover:bg-[#FFFAEB] hover:text-[#522300];
  }

  .btn-add {
    @apply flex flex-row justify-center items-center gap-3 bg-[#E7F5FF] rounded text-[#228BE6] pl-3 pr-6 py-3 hover:bg-[#228BE6] hover:text-[#FFFFFF] w-40 font-semibold text-base leading-6;
  }

  .uploader {
    @apply flex-[auto];
  }
}

.btn-loading {
  min-height: 3.25rem;
  border: none;
  align-items: center;
  background: linear-gradient(90deg, #ffe299 0%, #ffba3f 50%, #ffe299 100%);
  background-size: 300%;
  -webkit-animation: loading-bg 4s linear infinite;
  animation: loading-bg 4s linear infinite;
  cursor: not-allowed;
}

.custom-placeholder::placeholder {
  font-style: italic;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }

@-webkit-keyframes loading-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes loading-bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.uploader {
  flex: auto;
  @apply flex-[auto];
}