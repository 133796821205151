@media (max-width: 632px) {
    .mobile-padding {
        @apply px-5;
    }

    .mobile-page-padding {
        @apply p-4;
    }

    .mobile-bottom-margin {
        @apply mt-10 mb-20;
    }

    .mobile-container {
        @apply gap-x-2 gap-y-2 grid-cols-[0.25fr_minmax(3.7rem,1fr)_0.25fr];
    }

    .mobile-loan-container {
        @apply flex-col;
    }

    .mobile-eq-container {
        @apply flex flex-col relative gap-4;
    }

    .lettering {
        @apply hidden;
    }

    .mobile-completed {
        @apply block absolute right-4 top-4;
    }

    .footer-padding {
        @apply px-2;
    }

    .footer-simpl {
        @apply flex-col;
    }

    .footer-copy {
        @apply flex justify-center justify-items-center items-center;
    }

    .footer-mobile {
        @apply hidden;
    }

    .mobile-cont {
        @apply block min-h-[100px] auto-cols-[1fr] gap-x-10 gap-y-10 grid-cols-[1fr] grid-rows-[auto] align-top;
    }

    .container-mob {
        @apply block grid-cols-[0.5fr];
    }

    .mobile-form-pad {
        @apply p-4
    }

    .btn-container-mob {
        @apply flex flex-col gap-6
      }

    .popup-mob {
        @apply w-[90%]
    }

    .popup-mob-but {
        @apply flex-col gap-4 justify-center;
    }

    .popup-mob-but-pr {
        @apply max-w-[100%] ml-0
    }

    .mobile-title {
        @apply not-italic font-bold text-2xl leading-[120%] text-[#171718] flex-none order-none self-stretch grow-0;
    }

    .mobile-offercard {
        @apply m-0
    }

    .mobile-offercard-spacing {
        @apply flex justify-center justify-items-center w-full pr-0 mb-4
    }

    .mobile-offercard-margin {
        @apply mb-2
    }

    .mobile-offercard-margin2 {
        @apply mb-4
    }

    .mobile-widget {
        @apply flex flex-col h-auto text-center
    }

    .submit-content-mob {
        @apply block flex flex-col w-[100%] justify-self-center justify-center z-[3] h-fit p-6 bg-white border border-neutral-300 shadow-[0px_20px_25px_rgba(0,0,0,0.1),0px_10px_10px_rgba(0,0,0,0.04)] rounded-lg border-solid;
    }
    .docubar-nr {
        @apply flex w-48 h-8 justify-center items-center self-center  font-semibold text-center text-sm rounded-[999px] border-2 border-solid border-[#171718] text-white ;
    }
    
    .docubar-nrCurrrent {
        @apply flex w-48 h-8 justify-center items-center self-center  font-semibold text-center text-sm rounded-[999px] bg-[#171718] border-2 border-solid border-[#171718] text-white ;
    }
    
    .docubar-nr-grey {
        @apply flex w-48 h-8 justify-center items-center self-center  font-semibold text-center text-sm bg-transparent rounded-[999px] border-2 border-solid border-[#D4D4D4] text-neutral-300 ;
    }

    .mobile-sign-container {
        @apply flex-col gap-0;
    }
}



@media (min-width: 632px) {
    .hideit {
        @apply hidden;
    }

    .docubar-nr {
        @apply flex w-32 h-8 justify-center items-center self-center  font-semibold text-center text-sm rounded-[999px] border-2 border-solid border-[#171718] text-white ;
    }
    
    .docubar-nrCurrrent {
        @apply flex w-32 h-8 justify-center items-center self-center  font-semibold text-center text-sm rounded-[999px] bg-[#171718] border-2 border-solid border-[#171718] text-white ;
    }
    
    .docubar-nr-grey {
        @apply flex w-32 h-8 justify-center items-center self-center  font-semibold text-center text-sm bg-transparent rounded-[999px] border-2 border-solid border-[#D4D4D4] text-neutral-300 ;
    }
}

.submit-content-mob {
    @apply block flex flex-col w-[100%] justify-self-center justify-center z-[3] h-fit p-6 bg-white border border-neutral-300 shadow-[0px_20px_25px_rgba(0,0,0,0.1),0px_10px_10px_rgba(0,0,0,0.04)] rounded-lg border-solid;
  }

.hideall {
    @apply hidden;
}

.docubar-text {
    @apply text-[#171718] font-semibold text-center text-sm leading-[21px] ;
}


.docubar-conn {
    @apply flex self-center w-full h-0.5 bg-[#171718] m-2 rounded-[99px];
}

.docubar-conn-grey {
    @apply flex self-center w-full h-0.5 bg-neutral-300 m-2 rounded-[99px] text-neutral-300;
}

.popup-modal{
    @apply flex justify-center items-center fixed z-[3] h-screen w-full bg-[rgba(0,0,0,0.5)]

}

.submit-modal {
    @apply flex justify-center items-center z-[3] fixed h-screen w-full bg-[rgba(0,0,0,0.5)]
  }

.submit-content {
    @apply fixed flex flex-col w-[560px] justify-self-center justify-center z-[3] h-fit p-6 bg-white border border-neutral-300 shadow-[0px_20px_25px_rgba(0,0,0,0.1),0px_10px_10px_rgba(0,0,0,0.04)] rounded-lg border-solid;
  }

.popup-content{
    @apply flex justify-center h-fit;
}

.btn--upload {
    @apply flex-[0.3];
  }


  /* content-['Browse'] */
/* CUSTOM UPLOAD FIELD + DESIGN
<div class="flex flex-col content-center items-center gap-2 rounded-lg border border-dashed border-neutral-300 bg-neutral-100 p-10">
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_4493_50745)">
      <path d="M16 16L12 12L8 16" stroke="#228BE6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M12 12V21" stroke="#228BE6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M20.3895 18.3905C21.3648 17.8587 22.1353 17.0174 22.5793 15.9991C23.0234 14.9808 23.1157 13.8437 22.8417 12.7672C22.5677 11.6906 21.943 10.736 21.0661 10.0539C20.1893 9.37185 19.1103 9.00121 17.9995 9.00047H16.7395C16.4368 7.82971 15.8726 6.74281 15.0894 5.82147C14.3062 4.90012 13.3243 4.16832 12.2176 3.68108C11.1108 3.19384 9.90802 2.96383 8.69959 3.00835C7.49116 3.05288 6.30854 3.37077 5.24065 3.93814C4.17276 4.5055 3.24738 5.30758 2.53409 6.28405C1.8208 7.26053 1.33816 8.38601 1.12245 9.57587C0.906742 10.7657 0.963577 11.989 1.28869 13.1537C1.61379 14.3185 2.19871 15.3943 2.99947 16.3005" stroke="#228BE6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16 16L12 12L8 16" stroke="#228BE6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_4493_50745">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
  <div class="text-neutral-600">Drop files here or <a class="cursor-pointer text-sky-500 underline transition-all duration-200 hover:text-sky-600">Browse</a></div>
  <div class="text-sm text-neutral-500">Allowed file types: JPG, PNG, PDF</div>
</div> */