:root {
    /**
      * @tokens Core Colors
      * @presenter Color
      */
    --color-white: rgba(255, 255, 255, 1);
    --color-gray-50: #fafafa;
    --color-gray-100: #f5f5f5;
    --color-gray-200: #e5e5e5;
    --color-gray-300: #d4d4d4;
    --color-gray-400: #a3a3a3;
    --color-gray-500: #737373;
    --color-gray-600: #525252;
    --color-gray-700: #404040;
    --color-gray-800: #262626;
    --color-gray-900: #171717;
    --color-black: rgba(0, 0, 0, 1);
  
    --color-brand-50: #fff6e6;
    --color-brand-100: #ffe6b8;
    --color-brand-200: #ffd48a;
    --color-brand-300: #ffc25c;
    --color-brand-400: #ffae2e;
    --color-brand-500: #ff9900;
    --color-brand-600: #d27200;
    --color-brand-700: #a64b00;
    --color-brand-800: #792900;
    --color-brand-900: #4d0f00;
  
    --color-red-50: #ffe9ec;
    --color-red-100: #ffc3cb;
    --color-red-200: #ffa2aa;
    --color-red-300: #ff838b;
    --color-red-400: #ff696d;
    --color-red-500: #fa5252;
    --color-red-600: #d23b36;
    --color-red-700: #a62a20;
    --color-red-800: #791c0f;
    --color-red-900: #4d1104;
  
    --color-pink-50: #ffe8f4;
    --color-pink-100: #ffc2df;
    --color-pink-200: #ff9ec9;
    --color-pink-300: #fb7fb1;
    --color-pink-400: #f26298;
    --color-pink-500: #e64980;
    --color-pink-600: #cb2f61;
    --color-pink-700: #a61b44;
    --color-pink-800: #790c2a;
    --color-pink-900: #4d0316;
  
    --color-grape-50: #f8e9ff;
    --color-grape-100: #ecc3ff;
    --color-grape-200: #e1a0fa;
    --color-grape-300: #d681f3;
    --color-grape-400: #ca64e9;
    --color-grape-500: #be4bdb;
    --color-grape-600: #a932c1;
    --color-grape-700: #8f1ea1;
    --color-grape-800: #710f79;
    --color-grape-900: #4d054d;
  
    --color-violet-50: #ece9ff;
    --color-violet-100: #cdc3ff;
    --color-violet-200: #b3a1ff;
    --color-violet-300: #9c83ff;
    --color-violet-400: #8968fc;
    --color-violet-500: #7950f2;
    --color-violet-600: #6335d2;
    --color-violet-700: #4e1fa6;
    --color-violet-800: #3a0f79;
    --color-violet-900: #26054d;
  
    --color-indigo-50: #e8f1ff;
    --color-indigo-100: #c2d7ff;
    --color-indigo-200: #9fbcff;
    --color-indigo-300: #80a2ff;
    --color-indigo-400: #6487ff;
    --color-indigo-500: #4c6ef5;
    --color-indigo-600: #314dd2;
    --color-indigo-700: #1b30a6;
    --color-indigo-800: #0c1979;
    --color-indigo-900: #03094d;
  
    --color-blue-50: #e6f8ff;
    --color-blue-100: #b8e8ff;
    --color-blue-200: #8ad5ff;
    --color-blue-300: #63befe;
    --color-blue-400: #40a5f4;
    --color-blue-500: #228be6;
    --color-blue-600: #0c6dce;
    --color-blue-700: #0050a6;
    --color-blue-800: #003579;
    --color-blue-900: #001d4d;
  
    --color-cyan-50: #e6fefd;
    --color-cyan-100: #b8f7f9;
    --color-cyan-200: #8aebf0;
    --color-cyan-300: #5cdae3;
    --color-cyan-400: #37c4d3;
    --color-cyan-500: #15aabf;
    --color-cyan-600: #0391ac;
    --color-cyan-700: #007592;
    --color-cyan-800: #005673;
    --color-cyan-900: #00364d;
  
    --color-teal-50: #e6fdf1;
    --color-teal-100: #b8f6da;
    --color-teal-200: #8aecc5;
    --color-teal-300: #5cdeb0;
    --color-teal-400: #35cd9b;
    --color-teal-500: #12b886;
    --color-teal-600: #01a67a;
    --color-teal-700: #008d6a;
    --color-teal-800: #006f56;
    --color-teal-900: #004b3c;
  
    --color-green-50: #e8fde9;
    --color-green-100: #c1f6c5;
    --color-green-200: #9deda5;
    --color-green-300: #7be188;
    --color-green-400: #5cd26e;
    --color-green-500: #40c057;
    --color-green-600: #2aa945;
    --color-green-700: #198d36;
    --color-green-800: #0c6d27;
    --color-green-900: #04491a;
  
    --color-lime-50: #f7ffe6;
    --color-lime-100: #e6fbb8;
    --color-lime-200: #d1f48a;
    --color-lime-300: #b9ea62;
    --color-lime-400: #9edb3e;
    --color-lime-500: #82c91e;
    --color-lime-600: #68b40b;
    --color-lime-700: #4e9900;
    --color-lime-800: #357800;
    --color-lime-900: #1e4d00;
  
    --color-yellow-50: #fff5e6;
    --color-yellow-100: #ffe5b8;
    --color-yellow-200: #ffd68a;
    --color-yellow-300: #ffca5c;
    --color-yellow-400: #ffbd2e;
    --color-yellow-500: #fab005;
    --color-yellow-600: #d29100;
    --color-yellow-700: #a67000;
    --color-yellow-800: #794f00;
    --color-yellow-900: #4d2f00;
  
    --color-orange-50: #ffede6;
    --color-orange-100: #ffcfb8;
    --color-orange-200: #ffb68a;
    --color-orange-300: #ffa15c;
    --color-orange-400: #ff8f33;
    --color-orange-500: #fd7e14;
    --color-orange-600: #d26600;
    --color-orange-700: #a64f00;
    --color-orange-800: #793900;
    --color-orange-900: #4d2300;
  }
  