.wrapper {
    width: 100%;
  }
  
  .title {
    font-size: var(--font-size-24);
    font-weight: var(--font-weight-bold);
    color: var(--text-color);
    margin-bottom: var(--spacing-16);
  }
  
  .loaderbar {
    width: 100%;
    height: var(--spacing-12);
    background: var(--background-color);
    overflow: hidden;
    border-radius: var(--radii-full);
    padding: var(--spacing-4);
    margin-bottom: var(--spacing-16);
  }
  
  .loader {
    height: 100%;
    background: var(--primary-color);
    border-radius: var(--radii-full);
    transition-property: all;
    transition-duration: 300ms;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .list {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-16);
  }
  
  /* Icon colors */
  .default {
    background: var(--background-color);
  }
  
  .success {
    background: var(--icon-color-success);
  }
  