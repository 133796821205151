@import "core-colors.css";

:root {
  /**
  * @tokens Color
  * @presenter Color
  */
  --text-color: var(--color-gray-900);
  /* Default text color for headlines. Provides good and legible contrast for our content.  @presenter Color */
  --text-color-weak: var(--color-gray-600);
  /* Default text color. Used as the main text color throughout the UI.  @presenter Color */
  --text-color-weaker: var(--color-gray-500);
  /* Weak text color. Used for e.g. introduction paragraphs and help texts that are coupled with form elements.  @presenter Color */
  --text-color-disabled: var(--color-gray-400);
  /* Weakest text color. Used for e.g. disabled input text and placeholder text in focused inputs.  @presenter Color */
  --text-color-brand: var(--color-brand-800);
  --text-color-inverted: var(--color-white);
  /* Default text color for headlines on inverted background.  @presenter Color */
  --text-color-weak-inverted: var(--color-gray-300);
  /* Default text color on inverted background.  @presenter Color */
  --text-color-weaker-inverted: var(--color-gray-400);
  /* Weak text color on inverted background.  @presenter Color */
  --text-color-info: var(--color-blue-600);
  --text-color-success: var(--color-green-600);
  --text-color-warning: var(--color-orange-600);
  --text-color-error: var(--color-red-600);

  --background-color: var(--color-gray-100);
  /* Default background color. Creates a consistent and neutral canvas for content.  @presenter Color */
  --background-color-raised: var(--color-gray-50);
  /* Slightly lighter than the default. Used for subtle section distinctions, enhancing organizational clarity.  @presenter Color */
  --background-color-surface: var(--color-white);
  /* Background for key elements like cards and modals. Highlights important content.  @presenter Color */
  --background-color-lowered: var(--color-gray-200);
  /* A darker shade for de-emphasizing less important elements, maintaining visual hierarchy without distraction.  @presenter Color */
  --background-color-inverted: var(--color-gray-900);
  /* Strong contrast background for special elements like footers.  @presenter Color */
  --background-color-inverted-raised: var(--color-gray-800);
  --background-color-disabled: var(--color-gray-200);
  --background-color-overlay: rgba(0, 0, 0, 0.6);
  --background-color-info: var(--color-blue-50);
  --background-color-success: var(--color-green-50);
  --background-color-warning: var(--color-yellow-50);
  --background-color-error: var(--color-red-50);

  --border-color: var(--color-gray-200);
  --border-color-strong: var(--color-gray-300);
  --border-color-brand: var(--color-brand-400);
  --border-color-inverted: var(--color-gray-600);
  --border-color-info: var(--color-blue-500);
  --border-color-info-weak: var(--color-blue-100);
  --border-color-success: var(--color-green-500);
  --border-color-success-weak: var(--color-green-100);
  --border-color-warning: var(--color-yellow-500);
  --border-color-warning-weak: var(--color-yellow-100);
  --border-color-error: var(--color-red-500);
  --border-color-error-weak: var(--color-red-100);

  --primary-color: var(--color-brand-400);
  --primary-color-hover: var(--color-brand-300);
  --secondary-color: var(--color-gray-900);
  --secondary-color-hover: var(--color-brand-50);
  --tertiary-color: var(--color-white);
  --tertiary-color-hover: var(--color-gray-50);
  --link-color: var(--color-blue-600);
  --link-color-hover: var(--color-blue-500);

  --icon-color: var(--text-color-weak);
  --icon-color-weaker: var(--text-color-weaker);
  --icon-color-strong: var(--text-color);
  --icon-color-inverted: var(--text-color-inverted);
  --icon-color-info: var(--text-color-info);
  --icon-color-success: var(--text-color-success);
  --icon-color-warning: var(--text-color-warning);
  --icon-color-error: var(--text-color-error);
  --icon-color-disabled: var(--text-color-disabled);

  --tag-color-red: var(--color-red-600);
  --tag-color-pink: var(--color-pink-600);
  --tag-color-grape: var(--color-grape-600);
  --tag-color-violet: var(--color-violet-600);
  --tag-color-indigo: var(--color-indigo-600);
  --tag-color-blue: var(--color-blue-600);
  --tag-color-cyan: var(--color-cyan-600);
  --tag-color-teal: var(--color-teal-600);
  --tag-color-green: var(--color-green-600);
  --tag-color-lime: var(--color-lime-600);
  --tag-color-yellow: var(--color-yellow-600);
  --tag-color-orange: var(--color-orange-600);
  --tag-color-gray: var(--color-gray-600);
  --tag-color-brand: var(--color-brand-300);

  --gradient-brand-1: linear-gradient(
    120deg,
    var(--background-color-surface) 29.69%,
    var(--background-color) 29.69%,
    var(--background-color-surface) 71.26%,
    var(--color-brand-400) 71.26%,
    var(--color-brand-300) 106.89%
  );
  --gradient-brand-2: linear-gradient(
    120deg,
    var(--background-color-surface) 0%,
    var(--background-color-surface) 59.39%,
    var(--color-brand-400) 59.39%,
    var(--color-brand-300) 106.89%
  );
  --gradient-brand-3: linear-gradient(
    120deg,
    var(--background-color) 0%,
    var(--background-color-surface) 59.39%,
    var(--background-color) 59.39%,
    var(--background-color-raised) 106.89%
  );
  --gradient-brand-4: linear-gradient(
    120deg,
    var(--color-gray-800) 0%,
    var(--color-gray-700) 59.39%,
    var(--color-gray-900) 59.39%,
    var(--color-gray-800) 106.89%
  );

  /**
  * @tokens Border
  * @presenter BorderRadius
  */
  --radii-none: 0;
  --radii-xs: 0.125rem;
  --radii-sm: 0.25rem;
  --radii: 0.5rem;
  /* Default border radius @presenter BorderRadius */
  --radii-lg: 1rem;
  --radii-full: 999px;

  /**
* @tokens Border
* @presenter Border
*/

  --border-size: 1px;
  --border-size-weak: 1.5px;
  --border-size-strong: 2px;
  --border-none: 0;

  /**
* @tokens Shadow
* @presenter Shadow
*/
  --shadow-sm: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  --shadow-base: 0px 1px 3px 0px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  --shadow-md: 0px 4px 6px -1px rgba(0, 0, 0, 0.1),
    0px 2px 4px -2px rgba(0, 0, 0, 0.1);
  --shadow-lg: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -4px rgba(0, 0, 0, 0.1);
  --shadow-xl: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 8px 10px -6px rgba(0, 0, 0, 0.1);
  --shadow-2xl: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
  --shadow-inner: 0px 2px 4px 0px rgba(0, 0, 0, 0.05) inset;
  --shadow-inverted: 0px -4px 6px -1px rgba(0, 0, 0, 0.1),
    0px -2px 4px -2px rgba(0, 0, 0, 0.1);

  /**
* @tokens Size
* @presenter Spacing
*/
  --container-size-large: 80rem;
  --container-size-medium: 64rem;
  --container-size-small: 48rem;

  --icon-size-tiny: 0.75rem;
  --icon-size-xs: 1rem;
  --icon-size-sm: 1.25rem;
  --icon-size: 1.5rem;
  --icon-size-md: 2rem;
  --icon-size-lg: 2.5rem;
  --icon-size-xl: 3rem;
  --icon-size-2xl: 4rem;

  /**
* @tokens Spacing
* @presenter Spacing
*/
  --global-padding: 2.5rem;
  --spacing-0: 0;
  --spacing-px: 1px;
  --spacing-2: 0.125rem;
  --spacing-4: 0.25rem;
  --spacing-8: 0.5rem;
  --spacing-12: 0.75rem;
  --spacing-16: 1rem;
  --spacing-20: 1.25rem;
  --spacing-24: 1.5rem;
  --spacing-32: 2rem;
  --spacing-40: 2.5rem;
  --spacing-48: 3rem;
  --spacing-64: 4rem;
  --spacing-80: 5rem;
  --spacing-120: 7.5rem;

  /**
* @tokens Transition
* @presenter Easing
*/
  --transition: all 0.2s;

  /**
  * @tokens-end
  */
  --opacity-muted: 0.7;

  /**
   * @tokens-end
   */

  --card-font-size: var(--font-size-base);
  --cardSmall-font-size: var(--font-size-14);
  --card-padding: var(--spacing-24);
  --cardSmall-padding: var(--spacing-16);
  --card-gap: var(--spacing-24);
  --cardSmall-gap: var(--spacing-16);
}

/* Tablet: styles applied to screens 991px wide and below */
@media (max-width: 991px) {
  :root {
    --spacing-32: 1.5rem;
    --spacing-40: 2rem;
    --spacing-48: 2.5rem;
    --spacing-64: 3rem;
    --spacing-80: 4rem;
    --spacing-120: 5rem;
  }
}

@media screen and (max-width: 767px) {
  :root {
    --global-padding: 1.25rem;
  }
}